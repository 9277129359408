import Header from './header';
import NavigationMobile from './navigation-mobile';
import PageHome from './pages/home';
import gsap from 'gsap';
import * as ScrollToPlugin from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Lenis from '@studio-freight/lenis'
import LazyLoad from "vanilla-lazyload";
import parallax from './parallax';
import Preloader from './preloader';
import browser from 'browser-detect';

require('intersection-observer');
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

let _root;
let lenis;
let _preloader;

class App {
    static start() {
        return new App()
    }

    constructor() {
        _root = this;
        
        this.startIntersectionObserver();

        Promise
        .all([
            App.domReady(),
        ])
        .then(_root.init.bind(_root))

    }

    static domReady() {
        return new Promise(resolve => {
            document.addEventListener('DOMContentLoaded', resolve);
            _preloader = new Preloader();
        })
    }

    startLenis() {
        lenis = new Lenis({
            lerp: 0.2,
            smooth: true
        });

        lenis.on('scroll', () => ScrollTrigger.update());

        const scrollFn = (time) => {
            lenis.raf(time);
            requestAnimationFrame(scrollFn);
        };
        
        requestAnimationFrame(scrollFn);
    }

    startIntersectionObserver() {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.3,
        };
        const observer = new IntersectionObserver(_root.observerCallback, observerOptions);

        document.querySelectorAll('.el-anim').forEach((el) => observer.observe(el));
        document.querySelectorAll('.el-anim-b').forEach((el) => observer.observe(el));

    }

    observerCallback(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('loaded');
            }
        });
    }
    
    static showPage() {

        const result = browser();

        if (result.name == "safari" && parseFloat(result.version) < 13) {
        } else {
            _root.startLenis();
        }

        gsap.config({
            autoSleep: 60,
            force3D: false,
            nullTargetWarn: false,
            units: { left: '%', top: '%', rotation: 'rad' },
        });

        new parallax();

        new LazyLoad({
            elements_selector: '.lazy',
            use_native: true,
            callback_loaded: _root.callBackImgLoaded
        });
        
        new Header();
        new NavigationMobile();
        new PageHome();

        const element = document.querySelectorAll('.scroll-page-link');

        if ('undefined' != typeof element && null != element) {
            element.forEach(function(item) {
                _root.addEvent(item, 'click', _root.onClickEvent);
            });
        }

    }

    onClickEvent(el) {
        const _link = el.currentTarget.getAttribute('href');
        lenis.scrollTo(_link);
        el.preventDefault();
    }

    callBackImgLoaded(trg) {
        trg.parentElement.classList.contains('img-loaded') ? trg.parentElement.classList.remove('img-loaded') : trg.parentElement.classList.add('img-loaded');
    }

    init() {
        App.showPage();
    }

    addEvent(el, type, handler) {
        if (el.attachEvent) el.attachEvent('on' + type, handler);
        else el.addEventListener(type, handler);
    }

    removeEvent(el, type, handler) {
        if (el.detachEvent) el.detachEvent('on' + type, handler);
        else el.removeEventListener(type, handler);
    }

}

App.start();