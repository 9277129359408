import SectionHero from "../sections/home/section-hero";
import SectionGallery from "../sections/home/section-gallery";
import SectionService from "../sections/home/section-service";

export default class PageHome {
    constructor(root) {
        this.init();
    }

    init() {
        //new SectionHero();
        new SectionGallery();
        new SectionService();
    }
}