import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import Splitting from "splitting";

export default class SectionService {
    constructor() {
        this.init();
    }

    init() {
        Splitting();
        const fx16Titles = [...document.querySelectorAll('.content__title[data-splitting][data-effect16]')];

        const scroll = () => {
    
            fx16Titles.forEach(title => {
                
                gsap.fromTo(title, {
                    transformOrigin: '50% 50%',
                    scale: 1.5,
                }, {
                    ease: 'none',
                    scale: 1,
                    scrollTrigger: {
                        trigger: title,
                        start: 'top bottom',
                        end: 'top top',
                        scrub: true,
                    }
                });
        
                gsap.fromTo(title.querySelectorAll('.word'), {
                    'will-change': 'opacity',
                    opacity: 0.1
                }, 
                {
                    ease: 'none',
                    opacity: 1,
                    stagger: 0.05,
                    scrollTrigger: {
                        trigger: title,
                        start: 'top bottom-=40%',
                        end: 'center top+=40%',
                        scrub: true,
                    }
                });
        
            });
        
        };

        scroll();
    }
}
